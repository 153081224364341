import React, {FC, useCallback, useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {Modal} from 'react-responsive-modal';
import Policy from "./Policy";
import {translate} from "../../utils";
import {useHistory} from "react-router-dom";

interface Props {
  setDisableOutsideClick?: (status: boolean) => void;
}

const TermsLinks: FC<Props> = ({setDisableOutsideClick}) => {
  const [visible, setVisible] = useState(false);
  const history: any = useHistory();

  useEffect(() => {
    if (history.location.pathname === "/privacy" || history.location.state?.privacy) {
      handleClick();
    }
  }, [history]);

  const handleClick = useCallback(() => {
    if (setDisableOutsideClick) setDisableOutsideClick(true);
    setVisible(true);
    setTimeout(() => {
      const modalElArr = document.querySelectorAll('.react-responsive-modal-container');
      modalElArr.forEach(item => {
        item.scrollTo(0, 0)
      })
    }, 0);
  }, [setDisableOutsideClick]);
  const handleClose = useCallback(() => {
    history.replace({...history.location, state: {}});
    setVisible(false);
    if (setDisableOutsideClick) setTimeout(() => setDisableOutsideClick(false), 0);
  }, [setDisableOutsideClick, history]);

  return (
    <>
      <div className='d-flex flex-wrap my-3 justify-content-center'>
        <div onClick={handleClick} className='terms-link'>{translate('policy', undefined, undefined, false)}</div>
        {/*<div onClick={handleClick } className='mx-2 terms-link'>{translate('TERMS')}</div>*/}
      </div>
      <Modal
        closeOnOverlayClick
        open={visible}
        onClose={handleClose}
        closeOnEsc
        // animationDuration={0}
        center
        showCloseIcon={false}>
        <Policy/>
      </Modal>
    </>

  );
}


export default observer(TermsLinks);