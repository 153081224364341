import React, {FC} from 'react';

interface Props {
}

const Policy: FC<Props> = () => {
  return (
    <>
      <h2 className='title-32'>PRIVACY NOTICE</h2>
      <div className='bold'>Effective Date: June 14, 2022</div>
      <div className='terms-content'>
        <p>Edplan and each Member that provides courses through the edplan.online care about the confidentiality and security of your information. This Privacy Policy applies to information that edplan (a AVEOCEO – FZE company) collects through the Edplan Site when you interact with Edplan, with Members, with other users, and generally with the Edplan Site.
        </p>
        <p>Your information is received and controlled by Edplan according to this Privacy Policy when you sign up for an Edplan account or otherwise use the Edplan Site.
        </p>
        <ul>
          <li className='fst-italic'>Edplan is solely and independently responsible for its own privacy practices. No Member is responsible for Edplan’s privacy practices.</li>
        </ul>
        <p>If you enroll in a course offered by a Member through the Edplan Site, information about you and your activity in the course is received and controlled also by that Member as described in this Privacy Policy.</p>
        <ul>
          <li className='fst-italic'>Each Member is solely and independently responsible for its own privacy practices. No Member is responsible for another Member’s privacy practices. Also, Edplan is not responsible for any Member’s privacy practices.</li>
        </ul>
        <p>If you do not accept the terms of this Privacy Policy, then please do not access, browse, or register for the Edplan Site or enroll in any courses. If you choose not to provide certain information required to provide you with various products and services offered on the Edplan Site, then you may not be able to establish a user account or obtain those products or services.
        </p>
        <p>Any version of this Privacy Policy in a language other than English is provided for convenience and you understand and agree that the English language version will control if there is any conflict.
        </p>
        <h4>INFORMATION THAT EDPLAN COLLECTS FROM YOU (INCLUDING PERSONAL INFORMATION)
        </h4>
        <p>Edplan directly collects information when you:
        </p>
        <ul>
          <li>sign up for an Edplan user account and create an Edplan user profile;
          </li>
          <li>enroll and participate in online courses;
          </li>
          <li>purchase a product or service ;
          </li>
          <li>send email messages to Edplan (including messages to learner support);
          </li>
          <li>participate in user surveys; and
          </li>
          <li>participate in public forums, webinars, and other interactive experiences on the Edplan Site.
          </li>
        </ul>
        <p>Edplan also indirectly collects usage information about your visit to the Edplan Site, as explained in the section below titled Cookies and Tracking Technologies.
        </p>
        <p>Edplan does not itself collect or store financial account numbers or identification issued by a governmental entity, employer, or other authority. Instead, if you choose to make a purchase on the Edplan Site, for example a Verified Certificate, you will be directed to Edplan’s third-party payment processor and Edplan’s third-party ID verification service provider. If you do not wish to submit the required authentication or payment information, then you will not be able to obtain a certificate via the Edplan Site.
        </p>
        <p>Edplan requires your Personal Information limited circumstances when you:</p>
        <ul>
          <li>sign up for an Edplan user account (name, username, email address);
          </li>
          <li>purchase a Verified Certificate (so that Edplan’s third-party vendor can authenticate your identity) or other product or service (so that Edplan’s third-party vendor can process payment);
          </li>
          <li>participate in remote proctoring or other course facilitation processes (so that Edplan’s third-party vendor can monitor and assess the integrity of your course activity); or
          </li>
          <li>earn a Verified Certificate (the certificate will have your name on it).
          </li>
        </ul>
        <p className='fst-italic'>To the extent that Edplan associates the information that Edplan collects directly or indirectly with an individual (for example, you), the association is based on Personal Information in your account profile.
        </p>
        <p>You may voluntarily choose to share additional Personal Information on the Edplan Site, for example:
        </p>
        <ul>
          <li>in your Edplan account profile (and you may display a limited or full profile to other Edplan Site users);
          </li>
          <li>in applying for financial assistance; or
          </li>
          <li>when participating in a course, forum, webinar, or other interactive experience on the Edplan Site (noting that in some cases, this information is shared with a third party that provides tools or other assistance for the Edplan Site or a course).
          </li>
        </ul>
        <p>Edplan encourages you to use discretion before voluntarily sharing additional Personal Information on the Edplan Site. (If you later choose to delete your Edplan account, deletion of your Personal Information will be subject to the process and limits outlined below.)
        </p>
        <p>Members do not receive your Personal Information unless and until you enroll in a course. If you enroll in a course, the Member that offers the course will receive Personal Information from your Edplan account profile plus the information that Edplan collects about your activity and performance in the course in which you enrolled.
        </p>
        <h4>Cookies And Tracking Technologies on the Edplan Site
        </h4>
        <p>Like many companies, Edplan uses Cookies and other common Tracking Technologies on the Edplan Site and in email communications to help Edplan better understand your use and needs of the Edplan Site, current and future Edplan-related products and services, and how Edplan and Members can improve. For example:
        </p>
        <ul>
          <li>based on your cookie settings, Edplan tracks information indicating, among other things, which pages of the Edplan Site were visited, the order in which they were visited, when they were visited, and which hyperlinks and other user interface controls were used;
          </li>
          <li>Edplan may log the IP address, operating system, and browser software (including mobile use and device type when you use Edplan native mobile apps) you used when accessing the Edplan Site, and Edplan may be able to determine from an IP address your Internet Service Provider and the geographic location of your point of connectivity; and
          </li>
          <li>Edplan may track and collect information from emails that Edplan sends to you, for example whether you opened the email or clicked on any links from the email.
          </li>
        </ul>
        <p>Edplan also partners with third parties to help us track and understand your activity on the Edplan Site, how you interact with Edplan’s social media, and how you find or may be referred to the Edplan Site. Individual Members may separately use Tracking Technologies within their courses and email communications to understand your activity within a course and how you find or may be referred to a course on the Edplan Site.
        </p>
        <p>Edplan and Members use Cookies and other Tracking Technologies for the following purposes:
        </p>
        <ul>
          <li>when necessary for system administration, security, and integrity to ensure the Edplan Site works properly for you. Without these technologies, the Edplan Site may not function correctly;
          </li>
          <li>to enable analytics, performance and functionality, to help Edplan and Members gather information about how visitors use the Edplan Site and monitor Edplan Site performance, and to enhance your experience by recognizing and remembering whether you have visited the Edplan Site before and may have any personal preferences.
          </li>
        </ul>
        <p>You have some options for managing Cookies and other Tracking Technologies. These include:
        </p>
        <ul>
          <li><b>Modifying your browser settings:</b> You should be able to control how and whether your web browser accepts Cookies by adjusting its privacy and security settings. The “help” feature of the menu bar on most browsers will tell you how to stop accepting new Cookies, how to receive notification of new Cookies, and how to disable existing Cookies. If you reject Edplan’s Cookies, many functions and conveniences of the Edplan Site may not work properly.
          </li>
          <li><b>Opting-out of certain third-party tracking and advertising:</b> Some of the third parties used by Edplan or a Member for Cookies and Tracking Technologies offer the ability to opt-out from their tracking by following processes described on their websites. A list of some such third parties is maintained and available at the Edplan learner Help Center and includes, for some, links to applicable opt-out processes. Neither Edplan nor any Member is responsible for the effectiveness of any third-party opt-out options.
          </li>
        </ul>
        <h4>HOW YOUR INFORMATION IS USED
        </h4>
        <p>Edplan and Members use information, including Personal Information, to carry out the following purposes:
        </p>
        <ul>
          <li>Operate and improve the Edplan Site – This includes creating and improving features that support the Edplan community and enable Edplan Site usage at scale. This also includes personalizing the Edplan Site, so your learning experience is tailored to your interests and needs.
          </li>
          <li>Create, administer, provide, and teach courses – This includes creating and improving the delivery of massive open online courses and programs on Edplan. This also includes personalizing courses, so your learning experience is tailored to your interests and needs, and assessing your performance and awarding certificates.
          </li>
          <li>Offer and improve products and services – This includes enabling you to navigate the Edplan Site, to enroll and participate in courses and programs on the Edplan Site, to learn effectively in such courses and programs, and to purchase or obtain products and services on the Edplan Site such as Verified Certificates.
          </li>
          <li>Develop and maintain the security and performance of the Edplan Site – This includes tracking Edplan Site outages and creating software solutions, detecting violations of the Honor Code and Terms of Service, and monitoring uses, misuses, and potential misuses of the Edplan Site.
          </li>
          <li>Communicate with you – This includes answering your course and platform questions, notifying you of course and Edplan Site maintenance and updates, marketing to you about course offerings, programs, news, and related products and services of Edplan or Edplan affiliates, and as permitted under applicable law, sending you communications about products or services of selected business partners that may be of interest to you.
          </li>
          <li>Support scientific research including, for example, in the areas of cognitive science and education – This includes collaborating to enable and conduct research about how learners access and master course materials online, with the goal of improving course outcomes.
          </li>
          <li>Track Edplan Site usage against goals and mission – This includes performing analytics to evaluate access to and performance in courses and course-related products and services and to report aggregate usage information (not Personal Information) to business partners and external audiences.
          </li>
          <li>Exercise, enforce, and comply with legal rights and obligations – This includes responding to subpoenas, court orders, or other legal process; and investigating, preventing, or taking action regarding illegal activities, suspected fraud, security or technical issues, or to protect the rights, property, or safety of Edplan, Members, or others, and as otherwise required by applicable law.
          </li>
        </ul>
        <p>Edplan or a Member may also seek your consent for additional uses of information, including Personal Information, and will use it only for the purpose described to you. All use of Personal Information is subject to applicable law.
        </p>
        <h4>HOW YOUR INFORMATION IS SHARED
        </h4>
        <p>Edplan and Members share information, including Personal Information, with third parties for the following purposes:
        </p>
        <ul>
          <li>With service providers, contractors, and other third parties that help carry out the uses described above – Edplan requires third parties to: (1) abide by this Privacy Policy and applicable law; (2) handle Personal Information in a confidential manner and maintain adequate security; and (3) use Personal Information only as needed to fulfill the relevant purpose(s). In some cases, the Edplan Site and individual courses are integrated with third-party services or contain links to websites published by third parties, including other content providers as well as service providers. These third parties are responsible for their own privacy practices, so you should pay attention anytime you are redirected to a third-party website and be sure to review its privacy policy.
          </li>
          <li>With other learners in courses that you take and with other visitors to the Edplan Site, to create an interactive learning environment, support class participation, and share course information – This includes sharing comments, coursework, or other information or content that you submit to a portion of the Edplan Site designed for viewing by other class members or for public communication. This also includes providing opportunities for you to communicate with other users who may have similar interests or educational goals, for instance, recommending specific study partners or connecting potential student mentees and mentors. In such cases, Edplan and each Member that offers a course in which you enrolled may use all information collected about you to determine who might be interested in communicating with you, but will only provide others your username (for clarity, no disclosure of your real name or email address).
          </li>
          <li>With employers, government programs, institutions, or other enterprises that sponsor your enrollment in a course for training or other educational purposes – If an entity pays for or otherwise sponsors your Verified Certificate or course participation, Edplan will share information with the entity as needed to confirm your enrollment, participation, progress, and completion status in that course.
          </li>
          <li>With affiliates of Edplan or a Member, or with successors in the event of a merger, acquisition, or reorganization, for their use consistent with this Privacy Policy.
          </li>
        </ul>
        <p>Edplan or a Member may also seek your consent for additional disclosures of information, including Personal Information, and will share it only as described to you. In addition, Edplan or a Member may share with the public and with third parties, including but not limited to researchers and business partners, information and Personal Information that is de-identified or aggregated in a manner that does not personally identify you.
        </p>
        <h4>HOW TO MANAGE YOUR PERSONAL INFORMATION
        </h4>
        <p>You can manage your Personal Information through learner features on the Edplan Site and requests to the Edplan learner support team. If your request involves information controlled by a Member, Edplan learner support will notify and coordinate with the appropriate Member to address your request. Edplan will provide support to the extent required by applicable law (for example, learners in the European Union) and more broadly when possible, as a courtesy in our sole discretion. Edplan will notify you in the event that Edplan is unable to meet a request that is not legally required. Similarly, Members will only be obligated to comply with requests to the extent legally required, but may choose to comply with other requests in their sole discretion.
        </p>
        <h4>Access and correct your Personal Information
        </h4>
        <p>Much of your Personal Information is available in your Edplan account. Your name, email address, and other identifiable profile information is editable through the profile settings in your Edplan account except as noted below. Your account dashboard lists all current and archived courses in which you have enrolled and includes links to any Verified Certificates you may have earned. The dashboard also contains copies of your answers and other participation and performance in courses. Please contact Edplan learner support to access and update this or other information. If your request involves information controlled by a Member, Edplan learner support will notify the appropriate Member of your request.
        </p>
        <h4>Restrict or object to the processing of Personal Information
        </h4>
        <p>If you want to manage emails and other communications to you, you may update your preferences in your Edplan account dashboard, follow the steps described in links at the bottom of email messages, or contact Edplan learner support. You may object to, or request that Edplan or a Member stop, its use of your Personal Information for other purposes by contacting the Edplan learner support team. If your request involves information controlled by a Member, Edplan learner support will notify the appropriate Member of your request. Please note that if you choose to restrict Edplan’s or a Member’s ability to process Personal Information, and the processing is otherwise required to provide you with various services and products offered on the Edplan Site, you may not be able to establish an Edplan user account or enroll for a course, and Edplan or a Member may not be able to provide you with those services or products.
        </p>
        <h4>Delete account and Personal Information
        </h4>
        <p>To request deletion of your Edplan account and Personal Information, you should click the button labeled “Delete my account” in your Edplan account settings. Because deletion on the Edplan system is a permanent action and cannot be reversed, Edplan may ask you to complete a process that aims to confirm your authority to manage the Edplan account affected by your request.
        </p>
        <p>With respect to your Edplan account, Edplan will permanently:
        </p>
        <ul>
          <li>deactivate your Edplan account,
          </li>
          <li>remove the active profile,
          </li>
          <li>retire your username, and
          </li>
          <li>remove you from Edplan email lists.
          </li>
        </ul>
        <p>With respect to your Personal Information, Edplan will permanently delete your Edplan account profile Personal Information from the Edplan Site.
        </p>
        <p>As noted above, to the extent that Edplan associates the information that Edplan collects directly or indirectly with an individual (for example, you), the association is based on Personal Information in your Edplan account profile. By erasing the Personal Information in your Edplan account profile, the remaining information about your activity on the Edplan Site will no longer be associated with you, except as noted below.
        </p>
        <p>These changes will be applied to data stores that are used for operation of the Edplan Site including course administration by Members. If you enrolled in any courses on the Edplan Site, the Edplan learner support team will share your request with the Members that offered those courses.
        </p>
        <p className='fst-italic'>Exceptions:
        </p>
        <ul>
          <li className='fst-italic'>Deletion will not apply to historical activity logs or archives unless and until these logs and data naturally "age-off" the Edplan system.
          </li>
          <li className='fst-italic'>Edplan does not track or index every time or every place on the Edplan Site when or where a learner may volunteer Personal Information, so neither Edplan nor Members will be able to help you locate or manage all such instances. Edplan encourages you to use discretion before voluntarily sharing your Personal Information on the Edplan Site.
          </li>
          <li className='fst-italic'>Edplan will archive course data (in a manner and to the extent permitted under applicable law) to serve its mission to enable scientific research on cognitive science and education. These archives are used to produce encrypted research data packages for Members, and Personal Information may not be deleted from research data packages retained by Members.
          </li>
          <li className='fst-italic'>Edplan and each applicable Member cannot always delete records of past interactions and transactions. For example, records relating to previous purchases on the Edplan Site must be retained for financial reporting, audit, and compliance reasons.
          </li>
          <li className='fst-italic'>Edplan and each applicable Member cannot always delete records of past interactions and transactions. For example, records relating to previous purchases on the Edplan Site must be retained for financial reporting, audit, and compliance reasons.
          </li>
        </ul>
        <h4>Data retention
        </h4>
        <p>Edplan will retain your Personal Information for as long as your account is active or as needed to provide you with services; to maintain a record of your transactions for financial reporting, audit, and compliance purposes; and to comply with Edplan’s legal obligations, resolve disputes, enforce its agreements, and as otherwise permitted by applicable law. If you enroll in a Member’s course, such Member will also retain your Personal Information for as long as needed to provide you with services; to maintain a record of your transactions for financial reporting, audit, and compliance purposes; and to comply with its legal obligations, resolve disputes, enforce its agreements, and as otherwise permitted by applicable law. Upon your request that Edplan deactivate your account and delete your information, Edplan will follow the process described above, including without limitation archiving your course data (in a manner and to the extent permitted under applicable law) to serve its mission to enable scientific research on cognitive science and education. These archives will be used to produce encrypted research data packages for Members, and each such Member may also keep and use course data for scientific research.
        </p>
        <h4>STORAGE & SECURITY
        </h4>
        <p>Edplan stores information, including Personal Information, on its own servers and also on servers of companies that Edplan hires to provide services. Each Member also stores information, including Personal Information, on its own servers and/or on servers of companies that the Member hires to provide services. In each case, information may be stored in the United Arab Emirates and in other countries where Edplan or a Member operates, and in countries where Edplan and each Member’s respective service providers operate. If you are in the European Union, Switzerland, or other regions with laws governing data collection and use, you acknowledge that Edplan and each Member may transfer, process and store your personal information in the United Arab Emirates and other countries, the privacy laws of which may be considered less strict than those of your region.
        </p>
        <p>Edplan controls its own copy of information collected through the Edplan Site and has an information security program designed to protect information in its possession or control. This is done through a variety of privacy and security policies, processes, and procedures. Edplan uses administrative, physical, and technical safeguards that reasonably and appropriately protect the confidentiality, integrity, and availability of the information that it collects, receives, stores, or transmits. Nonetheless, no method of transmission over the Internet, or method of electronic storage, is 100% secure; and therefore, Edplan cannot guarantee its absolute security. While Edplan works hard to ensure the integrity and security of its network and systems, Edplan cannot guarantee that its security measures will prevent "hackers" or other unauthorized persons from illegally accessing or obtaining information.
        </p>
        <p>If Edplan learns of a security breach involving its copy of your Personal Information, Edplan may attempt to notify you electronically so that you may take appropriate protective steps. By using the Edplan Site or providing Personal Information to Edplan, you agree that Edplan can communicate with you electronically regarding security, privacy, and administrative issues relating to your use of the Edplan Site. If a security systems breach occurs, Edplan may post a notice on the Edplan homepage (www.Edplan.org) or elsewhere on the Edplan Site and may send an email to you at the email address associated with your Edplan account. Depending on where you are located, you may have a legal right to receive notice of a security breach, involving your Personal Information, in writing.
        </p>
        <p>If a Member learns of a security breach involving that Member’s copy of your Personal Information, the Member may attempt to notify you electronically so that you may take appropriate protective steps. By enrolling in a Member’s course on the Edplan Site or providing Personal Information to the Member, you agree that the Member can communicate with you electronically regarding security, privacy, and administrative issues relating to your course enrollment and participation. If a security systems breach occurs, the affected Member may post a notice on the Edplan site and/or send an email to you at the email address associated with your enrollment in the Member’s course on the Edplan Site. Depending on where you are located, you may have a legal right to receive notice of a security breach, involving your Personal Information, in writing.
        </p>
        <h4>Privacy Policy Updates
        </h4>
        <p>This Privacy Policy will be reviewed and updated from time to time. When changes are made, the Privacy Policy will be labeled as "Revised (date)," indicating that you should review the new terms, which will be effective immediately upon posting on this page, with an updated effective date. By accessing the Edplan Site after any changes have been made, you accept the modified Privacy Policy and any changes contained therein. In case you miss the notification referenced above, be sure to return to this page periodically to ensure familiarity with the most current version of this Privacy Policy.
        </p>
        <h4>Contact Information
        </h4>
        <p>If you have privacy concerns, have disclosed data you would prefer to keep private, or would like to access the Personal Information that Edplan maintains about you, please contact us at <a
          href="mailto:support@edplan.online">support@edplan.online</a>. You may also write to us at: Dubai Digital Park, Office A5, Dtec, Dubai Silicon Oasis, Dubai, The United Arab Emirates. If your request involves a Member, Edplan will notify the appropriate Member of your request.
        </p>
        <h4>Refund information
        </h4>
        <p> If you have technical problems with the student's panel, please don’t hesitate to contact us, we’ll try to fix this issue. If you would still like a refund or you are requesting the refund for another reason, see the info below.
        </p>
        <p>Once your subscription for the course  starts you can’t get a refund for any payments you’ve already made. If you don’t want to prolong your subscription for any courses, you don’t make payment for any of the courses. You will still have access to the course until the end of the period you have paid for.
        </p>
        <p>Depending on your specific situation, you may be able to return money for the course  within 7 working days of purchase if the course hasn’t started yet.
        </p>
        <h4>Glossary
        </h4>
        <p>Cookies are unique identifiers usually in the form of small data files placed on your device that send certain information about your activity on the Edplan Site or in an email communication back to Edplan or the authorized third party that served the cookie. Edplan or third parties may also use Flash cookies.
        </p>
        <p>Edplan Site consists of all content and pages located within the Edplan.org web domain and all Edplan mobile applications.
        </p>
        <p>Member is each educational institution or other leading global institution or entity that provides courses through the Edplan Site.
        </p>
        <p>Personal Information is information that specifically identifies you or that, when combined with other information, could be used to identify you.
        </p>
        <p>Tracking Technologies are web beacons, clear gifs, pixels, and similar technologies that are also unique identifiers used to track your online activity but are not stored on your device.
        </p>
        <p>Verified Certificate is a virtual certificate that shows that you have successfully completed your Edplan course or program and verified your identity using your webcam and your acceptable form of photo ID.
        </p>
        <div className='bold'>Effective Date: June 14, 2022</div>
      </div>
    </>
  );
}

export default Policy;